
/* .account-deactivate-dialog {

 md-content {
     background-color: rgb(255, 255, 255);
 }

 md-tabs-wrapper {
     display: none;
 }

 md-dialog-content {
     max-width: 550px;
 }

 .narrow-content {
    
 }

 .content {
     min-height: 220px;
 }

 .min-width {
     min-width: 500px;
 }

} */